<template>
  <div>
    <h2>Госстатотчетность 1-Отходы</h2>
    <el-form
      ref="formedForm"
      label-position="top"
      label-width="120px"
    >
    <el-form-item label="Период">
        <el-row :gutter="10">
          <el-col :span="6">
            <el-date-picker
              style="width: 100%"
              value-format="yyyy"
              v-model="reportForm.date"
              type="year"
            >
            </el-date-picker>
          </el-col>
        </el-row>
    </el-form-item>
    <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item
            prop="organizationInfo"
            label="Объект / Филиал / Подразделение / Участок"
          >
            <el-select
            @change="changeOrg"
              style="width: 100%"
              clearable
              value-key="id"
              v-model="reportForm.organizationUnit"
              filterable
              placeholder="Выберите"
            >
              <el-option
                v-for="item in organizationHierarchyByType"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">
          <el-form-item label="Тип файла">
            <el-select
              clearable
              v-model="reportForm.fileType"
              placeholder="Формат отчета"
            >
              <el-option
                v-for="item in fileTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <div style="padding-bottom: 10px; height: 40px;">
            <br>
          </div>
          <el-button @click="downloadReport" type="primary" style="margin-left: 10px;"
            >Сформировать отчет</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
//import SearchBlock from "@/components/searchBlock";

export default {
  name: "Waste-1Report",
  //components: { SearchBlock },
  data() {
    return {
        reportForm: {
        organizationUnit: {},
        date: null,
        fileType: null,
        dateReport:null,
        responsiblePerson: {},
        inspector: {},
      },
      fileTypes: [
        {
          value: ".pdf",
          label: ".pdf",
        },
        {
          value: ".xls",
          label: ".xls",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      organizationHierarchyByType: "GETORGANIZATIHIERARCHYBYTYPE",
      listResponsiblePersonForSelect: "GETLISTRESPONSIBLEPERSONFORSEARCH",
      checkedPerson: "GETLISTCHECKEDPERSON",
    }),
  },
  methods: {

    changeOrg() {
      this.getListResponsiblePersonForSelect();
    }, 

      getOrganizationHierarchyByType() {
      this.$store.dispatch("getOrganizationHierarchyByType", {
        type: 3,
      });
    },
    downloadReport() {
      this.reportForm.responsiblePerson = this.listResponsiblePersonForSelect[0];
      this.reportForm.inspector = this.listResponsiblePersonForSelect[0];
      let data = {
        fileName: "waste-1-" + this.reportForm.date + this.reportForm.fileType,
        formData: this.reportForm,
      };
      console.log(this.reportForm);
      console.log(data);
      this.$store.dispatch("downloadWaste1Report", data);
    },
    getListResponsiblePersonForSelect() {
       console.log(this.reportForm.organizationUnit);
      let units = [];
      if (this.reportForm.organizationUnit !== null) {
        units.push(this.reportForm.organizationUnit.id);
      }
      this.$store.dispatch("getListResponsiblePersonForSelect", units);
    },
  },


  mounted() {
    this.getOrganizationHierarchyByType();
    this.getListResponsiblePersonForSelect(-1);
  },
};
</script>

<style scoped></style>
