<template>
  <div>
    <waste-1-report></waste-1-report>
  </div>
</template>

<script>
import Waste1Report from "@/components/ecologist/reports/Waste-1/Waste-1Report";
export default {
  name: "Waste-1View",
  components: { Waste1Report },
};
</script>

<style scoped></style>